import { IntegrationType } from '@shared/generated/graphql';
import { IntegrationConfigs } from '@shared/types/order';
import { enumToString } from 'clerk_common/enums';

export const getAvailableIntegrationTypes = (
  integrationConfigs: IntegrationConfigs | null
): IntegrationType[] => {
  if (!integrationConfigs?.integrations?.length) return [];

  return integrationConfigs?.integrations
    .map((i) => i.integrationType)
    .filter(Boolean) as IntegrationType[];
};

export const integrationTypesToSelectOptions = (
  options: IntegrationType[]
): { id: IntegrationType; label: string }[] => {
  return options.map((option) => ({
    id: option,
    label: enumToString(option),
  }));
};
